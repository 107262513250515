import React from "react";



export const viewObjects = { 
    cards: 'כרטיסים',
    groups_for_cards: 'קבוצות של כרטיסים',
    clubs: 'מועדונים',
    stores: 'חנויות',
    store_club_agreements: 'הסכמים מועדון חנוית',
    store_club_agreements_for_sid: 'הסכמים מועדון חנוית',
    users: 'משתמשים',
    cards_load: 'טעינת כרטיסים',
    card_usage_history: 'היסטוריית שימוש בכרטיס',
    club_load_history: 'היסטוריית טעינות בארנק',
    request_logs: 'לוגי בקשות',
    reports_for_buys_by_date: 'דוחות לפי חודשים',
    group_store_arrangement:'הסדרים בין חניות לקבוצות',
    club_store_payments: 'תשלומים ישירים מהמועדון לחנות',
    debt_for_stores: 'חובות בין קהילות קארד לחנויות',
    pay_for_stores: 'תשלומים מקהילות קארד לחנויות',
    store_pay_to_keilotkard: 'תשלומי חנויות לקהילות קארד',
    recruiters: 'משווקים',
    report_for_recruiters: 'דוחות למשווקים',
    pay_for_recruiters: 'תשלומים למשווקים',
    debt_for_recruiters: 'חובות למשווקים',
    vouchers: 'שוברים',
    vouchers_transactions: 'עסקאות שוברים',
    vouchers_units: 'יחידות שוברים',
    vouchers_units_cards: 'שוברים מוטענים לכרטיסים',
    vouchers_usage: 'היסטורית שימוש בשוברים',
    store_debet_per_month: 'תשלומים חודשיים לחנויות',
    page_num_to_vouchers_transactions: 'קישור בין עמודי תשלום לעסקאות שוברים',
    clients_users: 'איזור אישי - משתמשים',
    reports_stores: 'דיווחים על חנויות',
};

export const tableAccess = {
admin: {
  view: ['cards', 'groups_for_cards', 'clubs', 'stores', 'store_club_agreements', 'users', 'cards_load',
    'card_usage_history', 'club_load_history', 'request_logs','reports_for_buys_by_date',
    'group_store_arrangement', 'club_store_payments', 'debt_for_stores', 'pay_for_stores',
    'store_pay_to_keilotkard', 'recruiters', 'report_for_recruiters', 'pay_for_recruiters',
    'debt_for_recruiters', 'vouchers', 'vouchers_transactions', 'vouchers_units', 'vouchers_units_cards',
    'vouchers_usage', 'store_debet_per_month', 'page_num_to_vouchers_transactions', 'clients_users',
    'reports_stores'
  ],
  edit: ['cards', 'clubs', 'stores', 'groups_for_cards', 'users', 'store_club_agreements', 'group_store_arrangement', 'card_usage_history',
    'club_store_payments', 'club_load_history', 'pay_for_stores', 'store_pay_to_keilotkard', 'recruiters',
    'page_num_to_vouchers_transactions'
  ],
  add: ['cards', 'clubs', 'stores', 'groups_for_cards', 'group_store_arrangement', 'pay_for_stores', 'recruiters',
    'pay_for_recruiters', 'page_num_to_vouchers_transactions'
  ]
},
club: {
  view: ['cards', 'groups_for_cards', 'cards_load', 'card_usage_history', 'club_load_history',
    'store_club_agreements_for_sid', 'group_store_arrangement', 'club_store_payments', 'reports_for_buys_by_date',
    'vouchers_transactions', 'vouchers_units', 'vouchers_units_cards', 'vouchers_usage'
  ],
  edit: ['cards', 'groups_for_cards', 'club_store_payments', 'club_load_history', 'vouchers_units'],
  add: ['groups_for_cards']
},
'storeManager': {
  view: ['card_usage_history', 'store_club_agreements', 'reports_for_buys_by_date', 'club_store_payments',
    'group_store_arrangement', 'debt_for_stores', 'pay_for_stores', 'store_pay_to_keilotkard', 'vouchers', 'vouchers_transactions',
    'vouchers_units', 'vouchers_usage', 'store_debet_per_month'
  ],
  edit: ['group_store_arrangement', 'store_club_agreements', 'vouchers'],
  add: ['group_store_arrangement', 'vouchers', 'vouchers_transactions']
},
'store': {
  view: ['last_usege_for_store'],
  edit: [],
  add: []
},
'recruiter': {
  view: ['report_for_recruiters', 'pay_for_recruiters', 'debt_for_recruiters'],
  edit: [],
  add: []
}
};


export function ChooseTable(props) {
    const {table, role, isMobile} = props;
    return (
        <div className="bot" style={{position : isMobile ? "static" : ""}}>
        <div className="bs">
          {tableAccess[role]['view'].map((obj) => {
            return (
              <a 
              href={`?table=${obj}`}
              key={obj}
              >
                <button
                  key={obj}
                  style={{
                    backgroundColor:
                      table === obj ? "#5600ff" : "white",
                      color: table === obj ? "white" : "black",
                  }}
                  className="b"
                >
                  {viewObjects[obj]}
                </button>
              </a>
            );
          })}
        </div>
      </div>
    )
}
