import React, { useEffect, useState } from "react";

export function GropeStore(props) {
    const { grops, setGrops } = props;
    const [show, setshow] = useState([]);

    const handleCheckboxChange1 = (index) => {
        let arr = [...grops];
        for (let i in arr) {
            arr[i] = [ ...grops[i] ];
        }
        for (let j in arr[index][1]) {
            arr[index][1][j] = { ...grops[index][1][j] };
            // if(props.pay ===  arr[index][1][j].payment  ){
                arr[index][1][j].checked = !grops[index][2];
            // }
        }
        arr[index][2] = !grops[index][2];
        // setIsChecked1(!isChecked1);
        setGrops(arr);
        props.setallStore(false)
    };

    const handleCheckboxChange2 = (indexi, indexj) => {
        
        let arr = [...grops];
        arr[indexi][1][indexj].checked = !grops[indexi][1][indexj].checked;
        if (!grops[indexi][1][indexj].checked) {
            arr[indexi][2] = false;
        }
        
        // setIsChecked1(!isChecked1);
        setGrops(arr);
        props.setallStore(false)
    };

    const hndelShow = (index) => {
        let arr = [...show];
        arr[index] = !show[index];
        setshow(arr);
    };

    useEffect(() => {
       if (grops.length === 0) {
        props.setallStore(false)
            return;
        }
        let arr = [...grops];
        for (let i in arr) {
            // console.log(grops[i]);
            arr[i] = [...grops[i]] 
            arr[i][2] = props.allStore ;
            for (let j in arr[i][1]) {
                arr[i][1][j] = { ...grops[i][1][j] };
                //if(props.pay === arr[i][1][j].payment  ){
                    arr[i][1][j].checked = props.allStore;
                //}
            }
            
        }
        // arr[indexi][1][indexj].checked = !grops[indexi][1][indexj].checked;
        // setIsChecked1(!isChecked1);
        setGrops(arr);

        
    }, [props.allStore, props.allStore1])
    return (
        <div style={{ margin: "10px" }}>
            {grops.map((grope, index) => {
                // console.log( grope[1].length);
                // if(! grope[1].length === 0){
                    
                //     return;
                // }
                return (
                    <div
                        className="grope"
                        style={{ marginTop: "5px", backgroundColor: "rgb(134, 82, 45)" }}
                        key={grope[0]}
                    >
                        <div style={{ display: "flex", backgroundColor: "rgb(210, 160, 124)" }}>
                            <label style={{ display: "flex", flexWrap: "nowrap" }}>
                                <input
                                    style={{ width: "1cm" }}
                                    type="checkbox"
                                    checked={grope[2]}
                                    onChange={() => handleCheckboxChange1(index)}
                                />
                                <span style={{ marginLeft: "5px" }}>{grope[0]}</span>
                            </label>

                            <span
                                style={{ marginRight: "auto", marginLeft: "3px" }}
                                onClick={() => hndelShow(index)}
                            >
                                &gt;
                            </span>
                        </div>
                        {show[index] &&
                            grope[1].map((card, ind) => {
                                if(!card.payment.includes(props.pay)|| props.pay === ""){
                                    return false;
                                }
                                return (
                                    <div key={card.name}>
                                        <label style={{ display: "flex", flexWrap: "wrap" }}>
                                            <input
                                                style={{ width: "1cm" }}
                                                type="checkbox"
                                                checked={grops[index][1][ind].checked}
                                                onChange={() => handleCheckboxChange2(index, ind)}
                                            />
                                            <span style={{ marginLeft: "5px" }}>
                                                {card.name }<span style={{fontSize : "10px"}}>{""+card.payment+""}</span>
                                            </span>
                                        </label>
                                    </div>
                                );
                            })}
                    </div>
                );
            })}
        </div>
    );
}
