import { useState } from "react";
import url from "../../../url ";

export function RemoveCards(props) {
    const { setPopupForRemoveCardsOpen, clubID } = props;
    const [firstNumber, setFirstNumber] = useState('');
    const [lastNumber, setLastNumber] = useState('');
    

    async function submit (e) {
        e.preventDefault();
        const res = await fetch(`${url}/admin/remove-cards`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                clubId: clubID,
                firstNumber,
                lastNumber,
            }),
            credentials: "include",
        });
        if (res.status === 200) {
            const resJson = await res.json();
            alert(resJson.message);
        } else {
            alert("אירעה שגיאה");
        }
    }

    return (
        <div 
            className="App" 
            style={{
                position: "fixed",
                zIndex: 10,
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)",
                width: "auto",
                maxWidth: "90%",
                overflow: "auto",
                height: "auto",
                maxHeight: "90%",
            }}
        >
            <h1>הוספת כרטיסים</h1>
            <form onSubmit={submit}
            style={{
                backgroundColor: "white",
                width: "auto",
                maxWidth: "90%",
                height: "auto",
                maxHeight: "90%",
            }}
            >
                <label htmlFor="firstNumber">מספר ראשון</label>
                <input type="text" id="firstNumber" name="firstNumber" required pattern="[0-9]{5}" value={firstNumber} onChange={e => setFirstNumber(e.target.value)}/>
                <label htmlFor="lastNumber">מספר אחרון</label>
                <input type="text" id="lastNumber" name="lastNumber" required pattern="[0-9]{5}" value={lastNumber} onChange={e => setLastNumber(e.target.value)}/>
            <button type="submit">הוסף</button>
            <button type="button" onClick={() => setPopupForRemoveCardsOpen(false)}>ביטול</button>
            </form>
        </div>
    );
}