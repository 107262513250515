import Main from './componntes/main/main';
import Login from './componntes/login/Login';
import SignUp from './componntes/login/signUp';
import ForgotPassword from './componntes/login/forgotPassword';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Add from './componntes/main/Add/Add';
import Edit from './componntes/main/Add/edit';
import SelectSearch1 from './componntes/main/Add/SelectSearch/SelectSearch';
import { CardLoading } from './componntes/main/table/Card loading/CardLoading';
// import { LodClubs } from './componntes/main/lodclubs/lodClubs';
import { StorFamiliar } from './componntes/storFamiliar/storFamiliar';
import { CardCode } from './componntes/storFamiliar/CardCode';
import { useEffect, useState } from 'react';
import { Connection } from './componntes/login/connection';
// import {Helmet} from "react-helmet";
import TwoStepsAuthenticat from './componntes/login/TwoStepsAuthenticat';
import LogViewer from './componntes/logs/LogViewer';
import WhatsAppButton from './componntes/WhatsAppButton/WhatsAppButton';
import Graps from './componntes/graps/MainGraps';
import { CreateApiKey } from './componntes/api/createApiKey';
import PhoneLogin from './componntes/login/PhoneLogin';



function App() {
  const [userData, setUserData] = useState({})
  const [user, setUser] = useState("")
console.log(user);
console.log(userData);
useEffect(()=>{
  setUserData( JSON.parse(  localStorage.getItem("data")))
},[])
  return (
    <div lang="he">
    
        {/* <Main />
        <Login /> */}
        <WhatsAppButton />
        <BrowserRouter>
          <div style={{
            backgroundColor: process.env.REACT_APP_NODE_ENV === 'development' ? 'rgba(0, 2, 0, 0.1)' : 'white',
          }}>
            <Routes>
            <Route index element={<div className="App">  <Connection obj={JSON.parse(localStorage.getItem('data'))} setUser={setUser}/></div>} />
            {/* <Route index element={<Login setUserData={setUserData} setUser={setUser}/>} /> */}
            <Route path="login" element={<Login setUserData={setUserData} setUser={setUser}/>} />
            <Route path="graps" element={<Graps />} />
            <Route path="signUp" element={<SignUp />} />
            <Route path='forgotPassword' element={<ForgotPassword />}/>
            <Route path="admin" element={<Main userData={userData} role='admin'/>} />
            <Route path="club/:id" element={<Main userData={userData} user={user} role='club'/>} />
            <Route path="storeManager/:id" element={<Main userData={userData} user={user} role='storeManager'/>} />
            <Route path="recruiter/:id" element={<Main userData={userData} user={user} role='recruiter'/>} />
            {/* <Route path="main/lodClubs/id" element={<LodClubs />} /> */}
            <Route path="add" element={<Add />} />
            <Route path="edit" element={<Edit />} />
            <Route path="SelectSearch" element={<SelectSearch1 />} />
            <Route path="cardLoading/:id" element={<CardLoading />} />
            <Route path='storFamiliar/:id/:code' element={<StorFamiliar />} />
            <Route path='CardCode/:id/:typeOFHistory' element={<CardCode userData={userData} user={user}/>} />
            <Route path='Connection' element={<div className="App">  <Connection obj={JSON.parse(localStorage.getItem('data'))} setUser={setUser}/></div>} />
            <Route path='TwoStepsAuthenticat' element={<TwoStepsAuthenticat />} />
            <Route path='logs' element={<LogViewer />} />
            <Route path="createApiKey" element={<CreateApiKey />} />
            <Route path="phone-login" element={<PhoneLogin />} />
            <Route path="*" element={<h1>404</h1>} />
            </Routes>
          </div>
        </BrowserRouter>
    </div>
  
  );
}

export default App;
