import SelectSearch2 from "./SelectSearch2";
import { useEffect, useState } from "react";


function MultySherch(props) {
    const [count, setCount] = useState(props.defaultValue.length > 0 ? props.defaultValue.length : 1);
    const [obj, setObj] = useState(props.defaultValue)
    const [arrid, setArrid] = useState(props.defaultValue)
    console.log(count);
    console.log(props.defaultValue.length);

    useEffect(() => {

    }, [])

    const hndel = ((obj) => {
        console.log(obj + " fghjkhgfdsa");
        let arr = [...arrid]


        arr[arr.length - 1].push(obj)
        console.log(arr);

        setArrid(arr)
        arr = arrNum(arr)
        props.hndel(arr, props.raelName)
    })

    const removal = (num) => {
        console.log(num);
        let arr = [...arrid]
        console.log(arr);

        arr = removeArrayWithNumber(arr, num[0]);
        console.log(arr);
        setArrid(arr)
        arr = arrNum(arr)

        props.hndel(arr, props.raelName)
    }

    const add = () => {
        console.log(arrid);
        for( let i of arrid){
            if(i.length === 0 ){
                alert('מלא את השדה הקודם ולאחר מכן תוכל להוסיף עוד הרשאה')
                return
            }
        }
        let arr = [...arrid]
        arr.push([])
        setArrid(arr)
    }

    const renderItems = () => {
        const items = [];
        for (let i = 0; i < arrid.length; i++) {
            console.log(arrid[i][1]);
            items.push(
                <div key={i}>
                    <SelectSearch2
                        
                        name={props.name}
                        table={props.table}
                        colmun={props.colmun}
                        hndel={hndel}
                        raelName={props.raelName}
                        defaultValue={arrid[i]}
                    />
                    <button type='button' style={{ display: "inline-block", backgroundColor: "gray" }} onClick={() => removal(arrid[i])}>הסרה</button>
                </div>
            );
        }
        return items;
    };
    return (
        <div>
            {renderItems()}
            <p><label>{props.name}</label></p>
            <span>הוסף הרשאה</span><button type='button' onClick={add}>+</button>
        </div>
    )
}
export default MultySherch

function arrNum(arr) {
    // יצירת מערך חדש לאחסון המספרים
    let num = [];

    // עבירה על כל מערך במערך המקורי
    for (let i = 0; i < arr.length; i++) {
        // בדיקה אם האיבר הוא מערך
        if (Array.isArray(arr[i])) {
            // עבירה על המערך הפנימי
            for (let j = 0; j < arr[i].length; j++) {
                // בדיקה אם האיבר במערך הפנימי הוא מספר
                if (typeof arr[i][j] === 'number') {
                    // הוספת המספר למערך החדש
                    num.push(arr[i][j]);
                }
            }
        }
    }

    // החזרת מערך שמכיל רק את המספרים
    return num;
}


function removeArrayWithNumber(array, number) {
    for (let i = 0; i < array.length; i++) {
        if (array[i].includes(number)) {
            array.splice(i, 1);
            console.log(`Removed the array containing the number ${number}.`);
            break; // על מנת להפסיק לאחר הסרת המערך
        }
    }
    return array;
}