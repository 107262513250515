import { useState } from "react";
import { Link } from "react-router-dom";
import "./Login.css";
import AutoHideAlert from "../alrt";
import url from "../url ";
import ReCAPTCHA from "react-google-recaptcha";
import { useRef } from 'react';
import ReactRecaptcha3 from 'react-google-recaptcha3';
import { useEffect } from "react";
import { Checkbox } from "@mui/material";

function SignUp() {
  const [email, setEmail] = useState("");
  const [phon, setphon] = useState("");

  const [passwordPhone, setpasswordPhone] = useState("");
  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");
  const [Spiner, setSpiner] = useState(false);
  const [Spiner2, setSpiner2] = useState(false);

  const [from, setFrom] = useState(true);
  const [err, setErr] = useState("");
  const [errOn, setErrOn] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [showPassword, setShowPassword] = useState(false);
  const [userName, setuserName] = useState('')
  const [isAlrt, setIsAlrt] = useState(false)
  const [message, setmessage] = useState('')
  const [isbot , setIsBot] = useState(false)
  const recaptcha = useRef ( ) ; 

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // קוד בשביל סמארטפון
  window.addEventListener("resize", () => {
    setIsMobile(window.innerWidth < 768);
  });

  const errMessage = (message) => {
    setErr(message);
    setErrOn(true);
    setEmail("");
    setPassword("");
    // setpasswordPhone("");
    setTimeout(() => {
      setErrOn(false);
      console.log("time");
    }, 5000);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handlePhoneChange = (event) => {
    setpasswordPhone(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  useEffect(() => {
    ReactRecaptcha3.init(process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY).then(
      (status) => {
        console.log(status);
      },(error) => {
        console.log(error);
      }
    ); 
  }, [])

  const handleSubmit1 = async (event) => {
    event.preventDefault(); 
    setSpiner(true)
    let v3 ;
    let v2 ;
    try {
    if(isbot){
      const captchaValue = recaptcha.current.getValue();
      if (!captchaValue) {
        // alert("Please verify the reCAPTCHA!");
        setmessage("  ")
        setIsAlrt(true)
        setTimeout(() => {
          setmessage("")
        setIsAlrt(false)
        }, 5000);
        return
      } 
      else{
        v2 = captchaValue
      }
    }else{
      const token =   await ReactRecaptcha3.getToken()
      v3 = token
      console.log(token);
      // console.log(site);
    }
   
      const response = await fetch(`${url}/user/sent-code/register`, {
        method: "post",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mail: email, 
          phone : phon,
          statusV3 : v3,
          statusV2 : v2
        }),
      });
      const data = await response.json();
      setSpiner(false)
      if (response.ok) {
        setFrom(false);
      } else {
        if(data.error === "need authenticate"){
          setIsBot(true)
          setmessage(data.error)
          setIsAlrt(true)
          setTimeout(() => {
            setmessage("")
          setIsAlrt(false)
          }, 5000);
          return
        }
        errMessage(data.error);
        console.error("Request failed with status:", response);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior.
    //const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    //const isMatch = regex.test(password);
    if(password.length<4){
      errMessage("הסיסמא צריכה להכיל לפחות ארבע תויים ")
      return
    }
    if(password !== password1){
      errMessage("הסיסמאות אינן תואמת");
      setPassword1("")
      return
    }
    setSpiner2(true)
    try {
      const response = await fetch( `${url}/user/register `, {
        method: "post",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json", // Specify the content type if sending JSON data.
        },
        body: JSON.stringify({
          verificationCode: passwordPhone,
          password: password,
          name : userName
        }),
      });
      const data = await response.json();
      setSpiner2(false)
      if (response.ok) {
        window.location.href = "/login";
        // alert("המשתמש נוצר בהצלחה");
        setmessage("המשתמש נוצר בהצלחה")
        setIsAlrt(true)
        setTimeout(() => {
          setmessage("")
        setIsAlrt(false)
        }, 5000);

      } else {
        errMessage(data.error);
        console.error("Request failed with status:", response.status);
      }
      // console.log("Response from server:", data.message);
    } catch (error) {
      console.error("An error occurred:", error);
      setmessage("שגיאה נסה שוב")
      setIsAlrt(true)
      setTimeout(() => {
        setmessage("")
      setIsAlrt(false)
      }, 5000);
    }
  };

  return (
    <div className="App">
       {isAlrt&& 
        
        <AutoHideAlert message={message} />
        }
      <h1>קהילות קארד</h1>
      <h3>הרשמה</h3>
      {from && (
        <form
          onSubmit={handleSubmit1}
          style={{ width: isMobile ? "70%" : "25%" }}
        >
          <div className="form-group">
            <label htmlFor="email">  הכנס כתובת מייל:</label>
            <input
              type="text"
              id="email"
              name="phone"
              value={email}
              onChange={handleEmailChange}
              required
            /><label htmlFor="phon">  הכנס מספר  טלפון:</label>
            <input
              type="text"
              id="phon"
              name="phone"
              value={phon}
              onChange={(e)=>setphon(e.target.value)}
              required
            />
          </div>
          {isbot &&  <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_V2_SITE_KEY} ref = { recaptcha }/>}
          {Spiner === true ?(
                <div className="divlod">
                  <div className="loader"></div>
                </div>) :
          <button type="submit">שלח סיסמה למייל</button> }
          <br />
          <Link to="/login">רשום כבר? לחץ כאן</Link>
          {errOn && <h6 className="err">{err}</h6>}
        </form>
      )}
      {!from && (
        <form
          onSubmit={handleSubmit}
          style={{ width: isMobile ? "70%" : "25%" }}
        >
          <div className="form-group">
            <label htmlFor="password"> הקש את קוד האימות שנשלח במייל</label>
            <input
              type="number"
              id="passwordPhone"
              name="passwordPhone"
              value={passwordPhone}
              onChange={handlePhoneChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="userName">   הקלד את שמך המלא (פרטי ומשפחה)/שם הסניף</label>
            <input
              type="text"
              id="userName"
              name="userName"
              value={userName}
              onChange={(e)=> setuserName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">הקלד סיסמה חדשה</label>
            <input
              type={showPassword ? "text" : "password"}
              // type="text"
              id="password"
              name="password"
              value={password}
              onChange={handlePasswordChange}
              required
              style={{paddingLeft: '0%'}}
            />
           <button type='button' style={{margin: '0%', padding :'0%', backgroundColor :'rgb(245, 185, 161)', position : 'absolute'}} className="password-eye"  onClick={togglePasswordVisibility}>👁️</button>
           <label htmlFor="password">אימות סיסמה </label>
            <input
              type={showPassword ? "text" : "password"}
              // type="text"
              id="password"
              name="password"
              value={password1}
              onChange={(e) => setPassword1(e.target.value)}
              required
              style={{paddingLeft: '0%'}}
            />
           <button type='button' style={{margin: '0%',padding :'0%', backgroundColor :'rgb(245, 185, 161)', position : 'absolute'}} className="password-eye"  onClick={togglePasswordVisibility}>👁️</button>
         
          </div>
          <p> הסיסמא חייבת להכיל ארבע ספרות</p>
          <div>
          <Checkbox style={{color: 'black'}} onChange={(e)=> setIsBot(e.target.checked)}  ></Checkbox>
          <p>אני מאשר את תנאי השימוש ומדיניות הפרטיות</p>
          </div>
          <br />
          {Spiner2 === true ?(
                <div className="divlod">
                  <div className="loader"></div>
                </div>) :
          <button type="submit">הרשמה</button>}
          {errOn && <h6 className="err">{err}</h6>}
        </form>
      )}
    </div>
  );
}

export default SignUp;
